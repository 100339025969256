/** @format */
// Colors
$primary-black: #000000;
$primary-white: #ffffff;
$primary-gold: #d58300;
$primary-green: #00aa34;
$primary-blue: #2699fb;
$primary-red: #d50000;
$primary-grey: #b9b9b9;
$light-gray: rgba(129, 127, 127, 0.37);
$gray: #e2e2e2;
