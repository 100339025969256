.search-container {
	width: 90%;
	margin-inline: auto;
	margin-top: 32px;
	@media (min-width: 768px) {
		width: 35%;
	}

	// .search-logo {
	// 	width: 75%;
	// 	margin-inline: auto;
	// 	margin-top: 32px;
	// 	@media (min-width: 768px) {
	// 		width: 35%;
	// 		margin-inline: initial;
	// 	}
	// }

	.search-box {
		// background-color: red;
		// width: 100%;
		// display: flex;
		// flex-direction: column;
		// align-items: flex-start;
		// grid-gap: 1rem;

		// padding-bottom: 8px;
	}
}
