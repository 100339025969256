@import "../../scss/config";

.disabled {
	background-color: red;
}
.button {
	width: 100%;
	border-radius: 7.5px;
	background-color: $primary-white;
	max-width: 600px;
	@include fluid-padding-double(
		$min_width,
		$max_width,
		$min_padding_14,
		$max_padding_14,
		$min_padding_20,
		$max_padding_20
	);
	//  padding: 14px 20px;
	height: fit-content;
	width: fit-content;
	@include d_flex(row, center, center);
	transition: all 0.2s ease;
	label {
		white-space: nowrap;
	}
}
.red_button_thick {
	border: 1.5px solid $primary-red !important;
	&:hover,
	&:focus,
	&:active {
		border: 1.5px solid $primary-red !important;
		background-color: $primary-red !important;
		label {
			color: $primary-white !important;
		}
	}
}

.blue_button {
	border: 2px solid$primary-blue;
	&:hover,
	&:focus,
	&:active {
		border: 2px solid$primary-blue;
		background-color: $primary-blue;
		label {
			color: $primary-white;
		}
		path {
			fill: $primary-white;
		}
	}
}
.green_button {
	border: 2px solid $primary-green;
	&:hover,
	&:focus,
	&:active {
		border: 2px solid $primary-green;
		background-color: $primary-green;
		label {
			color: $primary-white;
		}
	}
}

.black_button {
	border: 1px solid $primary-black;
	&:hover,
	&:focus,
	&:active {
		border: 2px solid $primary-black;
	}
}

.red_button {
	border: 1px solid $primary-red;
	&:hover,
	&:focus,
	&:active {
		border: 1px solid $light-red;
	}
}
.red_button_fill {
	background: $light-red !important;
}
.button_two {
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 7.5px;
	background-color: $primary-white;
	@include fluid-padding-double(
		$min_width,
		$max_width,
		$min_padding_14,
		$max_padding_14,
		$min_padding_20,
		$max_padding_20
	);
	//  padding: 14px 20px;
	height: fit-content;
	width: fit-content;
	@include d_flex(row, center, center);
	transition: all 0.2s ease;
	label {
		white-space: nowrap;
	}
}
