@import "../../scss/config";

.main_section_sec {
	// background-color: red;
}
.map_section {
	.map_label {
		margin-bottom: -105px;
	}
	.app_map {
		width: 600px;
		height: 500px;
	}
	width: 100%;
}
.trip_page_flex {
	@media screen and (min-width: 820px) {
		display: flex;
	}
	.ongoing_trip_content {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
	}
}

.ongoing_trip {
	.order_card {
		.order_card_title {
			border: 1px solid #91afd9;
		}

		.order_card_body {
			border: 1px solid #91afd9;
			.order_card_item {
				border-bottom: 1px solid #91afd9;
				&:last-child {
					border-bottom: none !important;
				}
			}
		}
		.bold_text {
			font-weight: bold;
		}
	}

	.blue_text {
		color: #1d3557;
	}
	.title {
		font-size: 1.2rem;
		font-weight: bold;
	}
	&_content {
		padding: 0 2rem;
	}

	&_header {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		grid-area: 3rem;

		&_left {
			width: 30%;
			@media (max-width: 768px) {
				width: 50%;
			}

			@media (max-width: 426px) {
				width: 100%;
			}
		}

		&_right {
			background-color: #ceddf3;
			padding: 0.6rem;
		}
	}
	.ongoing_flex_col {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}

	.people {
		margin-top: 4rem;
	}
	.path_text {
		color: #1d3557;
		font-size: 1.5rem;
		font-weight: bold;
	}

	.text_base {
		font-size: 1rem;
	}

	.text_wrap {
		// width: 70%;
		@media (max-width: 768px) {
			width: 100%;
		}
	}
}
