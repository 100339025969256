@import "../../scss/config";

.main_section_sec {
	box-shadow: 0px 2.5px 5px #00000029;
	border-radius: 2.5px;
}
.map_section {
	.map_label {
		margin-bottom: -105px;
	}
	.app_map {
		width: 600px;
		height: 500px;
	}
}
.app_map {
	width: 600px;
	height: 500px;
}
.scroll-container {
	max-height: 500px;
}

.bottom_left_section_alt {
	table {
		overflow: scroll;
		border-collapse: separate;
		border-spacing: 0;
		position: relative;
		tbody {
			tr {
				td {
					border-bottom: 1px solid $light-gray;
					padding: 0.8em 0;
				}
			}
		}
	}
}

.ongoing-trip {
	margin-top: 1rem;
	display: flex;
	// align-items: center;
	justify-content: space-between;
	gap: 2px;

	&-left {
		width: 70%;
	}
	&-right {
		width: 40%;
		background-color: black;
		height: 300px;

		@media (max-width: 600px) {
			width: 100%;
		}
	}

	.card_flex {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}
}
