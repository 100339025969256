@import "../../scss/config";

.text_card_container {
  box-shadow: 0px 2.5px 5px #00000029;
  height: fit-content;
  position: relative;
  width: 100%;
  @include fluid-height(
    $min_width,
    $max_width,
    $min_height_200,
    $max_height_200
  );
  background: $primary-white;
  border-radius: 4px;
}
