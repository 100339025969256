/** @format */

/**
* A functions that creates responsive containers and fontsizes according
* to browser size. $bigVal is the value it should take on desktop and $smallVal
* is the value for mobile. Intermediate values are created according to screen
* sizes
*/

@function rfs($bigVal: $root-size, $smallVal: 14) {
  @return (
    calc(
      #{$smallVal}px + (#{$bigVal - $smallVal}) *
        ((100vw - 375px) / (1440 - 375))
    )
  );
}

/**
  * Converts a value from pixels to em
  */
@function em($val) {
  @return #{$val/$root-size}em;
}

/**
  * Converts a value from pixels to rem
  */
@function rem($val) {
  @return #{$val/$root-size}rem;
}
