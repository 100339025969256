.pagination li {
	padding: 4px 15px;
	border: 1px groove #de0000;
	height: 100%;
	// background-color: #000;
	width: inherit;
	display: flex;
	justify-content: center;
	align-items: center;
}

.pagination li a {
	cursor: pointer;
}

.pagination li:first-child {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}
.pagination li:last-child {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

.pagination ul {
	display: flex;
	background-color: pink;
}
ul.pagination {
	text-align: center;
	margin: 16px 0px 8px 0x;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	// background-color: #de0000;
}
.pagination__link--active {
	background: #f3564d;
	color: #fff;
}
.pagination_container {
	text-align: center;
	margin: 3rem auto;
	margin-bottom: 2rem;

	display: flex;
	justify-content: center;
	border-radius: 10px;
	height: 40px;
}

.prev_link {
	// border: none;
	// background: #f3564d;
}

.pagination__link--disabled {
	// background: #f3564d;
	// color: #fff;
	opacity: 0.4;
}
