/** @format */

$font-weights: (
  "light": 400,
  "regular": 600,
  "medium": 700,
  "bold": 800,
  "bolder": 900,
);
// $font-family: "Source Sans Pro", sans-serif;
$font-family: "Nunito Sans", sans-serif;
$font-seconday: "Nunito Sans", sans-serif;
$root-size: 16;
$responsive: (
  // "xs": 425px,
  "sm": 500px,
  "md": 768px,
  "lg": 970px,
  "xl": 1200px,
  "xxl": 1600px
);

$padding: (
  0: 0,
  1: 0.5rem,
  2: 1rem,
  3: 1.25rem,
  4: 2.25rem,
  5: 3.5rem,
);

$colors: (
  "success": #00aa34,
  "dark": #000000,
  "dark-alt": #202124,
  "deep-blue": #071232,
  "white": #f7f7f2,
  "whiteAlt": #ffffff,
  "danger": #d50000,
  "light-red":#ffc7c7,
  "gold": #d1831c,
  "primary": #2699fb,
  "second-blue": #3865d7,
  "light-blue": #dfe8fc,
  "purple": #431172,
  "purple-light": #a562e3,
  "purple-alt": #551a8b,
  "primary-light": #f3f6ff,
  "dark-50": #2c2b2b9a,
  "gray-alt":#939393,
  "light": #8aa0a6,
  "grey": #b9b9b9,
  "grey-2": #e2e2e2,
  "carton": #c7aa83,
  "light-gray": #e3e3e3,
  "yellow": #de970b,
);
