@import "../../scss/config";

.main_section_sec {
  box-shadow: 0px 2.5px 5px #00000029;
  border-radius: 2.5px;
}
.map_section {
  .map_label {
    margin-bottom: -105px;
  }
  .app_map {
    width: 600px;
    height: 500px;
  }
}
