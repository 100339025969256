@import "../../scss/config";
.order_card {
	.order_card_title {
		// border: 1px solid #1d3557;
		color: #fff;
	}

	.order_card_body {
		border: 1px solid #1d3557;
		.order_card_item {
			border-bottom: 1px solid #1d3557;
			&:last-child {
				border-bottom: none !important;
			}
		}
	}
	.bold_text {
		font-weight: bold;
	}
}
