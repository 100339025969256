@import "../../scss/config";

.tabs_container {
	width: 100%;
	overflow: scroll;
	border-radius: 20px 20px 0px 0px;

	::-webkit-scrollbar {
		width: 0px !important;
	}
	ul {
		margin: 0;
		padding: 0;

		li {
			background: none;
			list-style-type: none;
			padding: 5px;
			margin-right: 15px;

			a {
				text-decoration: none;
				div {
					white-space: nowrap;
					color: $primary-black;
					font-weight: bold;
					border: 1px solid $primary-red;
					border-radius: 25px;
					padding: 6px 20px;
				}
			}
		}

		.nav_add {
			margin-left: auto;
			padding: 10px;
			width: fit-content;
			.normal_18px {
				padding-left: 6px;
			}
			.cursor_pointer {
				border: 1px solid $primary-green;
			}
			.btn_span {
				background: $primary-green;
				width: 18px;
				height: 18px;
				border-radius: 50%;
				margin-left: 10px;
				border: none !important;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			svg {
				color: $primary-white;
				fill: $primary-white;
			}
			label {
				white-space: nowrap;
				color: $primary-green;
				font-weight: bold;
			}
		}
		.active_nav_item {
			a {
				div {
					background: $light-red !important;
				}
			}
		}
		.active_nav_add {
			.cursor_pointer {
				background: $primary-green;
			}

			.btn_span {
				background: $primary-white;
			}

			svg {
				color: $primary-green;
				fill: $primary-green;
			}
			label {
				color: $primary-white;
			}
		}
	}

	label {
		white-space: nowrap;
	}
}

.cap_tab_container {
	.caps_container_cont {
		position: relative;
		width: 145px;
		height: 100px;
		.caps_container {
			position: absolute;
			width: 145px;
			border: 1px solid $grey_white;
			border-radius: 90px;
			height: 21px;
			max-height: 21px;
			top: 35%;
		}

		.cap_tab {
			padding: 3px 15px;
			border-radius: 90px;
			margin-top: -1px;
			z-index: 99;
			top: 37%;

			position: absolute;
			h6 {
				line-height: 12px;
			}
		}

		.cap_tab0 {
			left: 0;
		}
		.cap_tab2 {
			right: 0 !important;
		}

		.active_cap_tab {
			border: 1px solid $border-gray;
			h6 {
				font-weight: bold !important;
			}
		}
	}
}
