// .setting-sidenav {
// 	width: 300px;
// }
// .surge_container {
// 	.react-select-container {
// 		width: 100% !important;
// 	}
// 	.react-select__control {
// 		width: 300px !important;
// 		margin-left: 15px;
// 	}
// }

.header_flex {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 2rem 3rem;
	gap: 4rem;

	.header_left,
	.header_right,
	.form_flex {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}
	.header_left {
		width: 40%;
		grid-area: 2rem;
		@media (max-width: 768px) {
			width: 70%;
		}

		&_flex {
			display: flex;
			gap: 2rem;
			flex-wrap: wrap;
		}
	}

	.form_flex {
		flex-direction: row;
		grid-column-gap: 2rem;
		grid-row-gap: 0.5rem;
		flex-wrap: wrap;
	}

	.surge_flex {
		display: flex;
		gap: 2rem;
		align-items: center;
	}
}
