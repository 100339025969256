@import "../../scss/colors";
.order-card {
  min-width: 490px;
  width: 33%;
  .order-card-header {
    min-width: 360px;
    width: 100%;
  }
  .card-body {
    min-height: 69vh;
    max-height: 69vh;

    overflow-x: hidden;
    overflow-y: scroll;
  }
  .card-body-alt {
    margin-bottom: 100px !important;
    padding-right: 0!important;
  }
  .reject-btn {
    width: 80%;
    bottom: 70px;
    left: 10%;
    border: 2px solid $primary-red;
  }
}
.order_card {
  .order_card_title {
    border: 1px solid #e3e3e3;
  }

  .order_card_body {
    border: 1px solid #e3e3e3;
    .order_card_item {
      border-bottom: 1px solid #cecece;
      &:last-child {
        border-bottom: none !important;
      }
    }
  }
}
// Accordion styles


/* Style the buttons that are used to open and close the accordion panel */





/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(180deg) !important;
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  overflow-x: hidden;
  transition: max-height 0.3s ease;
}
.active-accordion-head{
 // border: 1px solid #e3e3e3;
  width: 100%;
}
// Accordion styles end
.receipt {
  .order_card_title,.order_card_body, .order_card_item{
    border: none !important;
  }}
@media screen {
  .receipt {
  .order_card_title,.order_card_body, .order_card_item{
    border: none !important;
  }
  display: none;
    font-size: 9px;
    p,
    h6,
    label {
      font-size: 9px;
    }
  }
}
