/** @format */
@import "mixins";
@import "variables";
/**
*  Creates the flex containers for mobile screens
*/

@for $var from 1 through 12 {
  .col-#{$var} {
    @include flexCalculator($var);
  }
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

/**
  * Creates the padding and margin for mobile screens
  */
@each $key1, $item in $padding {
  // Paddings fo general
  .p-#{$key1} {
    padding: $item !important;
  }
  .py-#{$key1} {
    padding-bottom: $item !important;
    padding-top: $item !important;
  }
  .px-#{$key1} {
    padding-left: $item !important;
    padding-right: $item !important;
  }
  .pb-#{$key1} {
    padding-bottom: $item !important;
  }
  .pt-#{$key1} {
    padding-top: $item !important;
  }
  .pr-#{$key1} {
    padding-right: $item !important;
  }
  .pl-#{$key1} {
    padding-left: $item !important;
  }
  // Margins for general
  .m-#{$key1} {
    margin: $item !important;
  }
  .mb-#{$key1} {
    margin-bottom: $item !important;
  }
  .mt-#{$key1} {
    margin-top: $item !important;
  }
  .mr-#{$key1} {
    margin-right: $item !important;
  }
  .ml-#{$key1} {
    margin-left: $item !important;
  }
  .my-#{$key1} {
    margin-bottom: $item !important;
    margin-top: $item !important;
  }
  .mx-#{$key1} {
    margin-left: $item !important;
    margin-right: $item !important;
  }
}

/**
  * Iterates through the responsive variables to declare containers for each breakpoints
  */
@each $key, $val in $responsive {
  @include media-breakpoint-up($key) {
    // display properties for each breakpoint
    .d-#{$key}-flex {
      display: flex !important;
    }
    .d-#{$key}-inline {
      display: inline !important;
    }
    .d-#{$key}-block {
      display: block !important;
    }
    .d-#{$key}-none {
      display: none !important;
    }
    .d-#{$key}-center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .d-#{$key}-justify-center {
      display: flex;
      justify-content: center;
    }
    .d-#{$key}-justify-between {
      display: flex;
      justify-content: space-between !important;
    }
    .d-#{$key}-justify-end {
      display: flex !important;
      justify-content: flex-end;
    }
    .d-#{$key}-justify-start {
      display: flex;
      justify-content: flex-start !important;
    }
    .d-#{$key}-align-center {
      display: flex !important;
      align-items: center;
    }
    .d-#{$key}-align-end {
      display: flex !important;
      align-items: flex-end;
    }
    .d-#{$key}-align-start {
      display: flex;
      align-items: flex-start;
    }
    // Flex
    .flex-#{$key}-column {
      flex-direction: column !important;
    }
    .flex-#{$key}-row {
      flex-direction: row !important;
    }
    .flex-#{$key}-wrap {
      flex-wrap: wrap;
    }

    .flex-#{$key}-no-wrap {
      flex-wrap: nowrap !important;
    }
    // Text alignment
    .text-#{$key}-center {
      text-align: center;
    }
    .text-#{$key}-left {
      text-align: left !important;
    }
    .text-#{$key}-right {
      text-align: right !important;
    }
    // View ports
    .vh-#{$key}-max {
      max-height: 100vh;
    }
    .vh-#{$key}-min {
      min-height: 100vh !important;
    }
    .vw-#{$key}-max {
      max-width: 100vw;
    }
    .vw-#{$key}-min {
      min-width: 100vw !important;
    }
    .col-#{$key}-option {
      width: 100%;
      flex: 0 0 ((100/12) * 2.4 * 1%);
      max-width: ((100/12) * 2.4 * 1%);
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .col-#{$key} {
      -ms-flex-preferred-size: 0;
      flex-basis: 0 !important;
      -ms-flex-positive: 1;
      flex-grow: 1 !important;
      max-width: 100%;
    }
    // Flex order
    .order-#{$key}-1 {
      order: 1 !important;
    }
    .order-#{$key}-2 {
      order: 2 !important;
    }
    .order-#{$key}-3 {
      order: 3 !important;
    }

    @for $var from 1 through 12 {
      .col-#{$key}-#{$var} {
        @include flexCalculator($var);
      }
    }

    // Creates the padding and margin for each breakpoints
    @each $key1, $item in $padding {
      .p-#{$key}-#{$key1} {
        padding: $item !important;
      }
      .py-#{$key}-#{$key1} {
        padding-bottom: $item !important;
        padding-top: $item !important;
      }
      .px-#{$key}-#{$key1} {
        padding-left: $item !important;
        padding-right: $item !important;
      }
      .pb-#{$key}-#{$key1} {
        padding-bottom: $item !important;
      }
      .pt-#{$key}-#{$key1} {
        padding-top: $item !important;
      }
      .pr-#{$key}-#{$key1} {
        padding-right: $item !important;
      }
      .pl-#{$key}-#{$key1} {
        padding-left: $item !important;
      }
      .m-#{$key}-#{$key1} {
        margin: $item !important;
      }
      .mb-#{$key}-#{$key1} {
        margin-bottom: $item !important;
      }
      .mt-#{$key}-#{$key1} {
        margin-top: $item !important;
      }
      .mr-#{$key}-#{$key1} {
        margin-right: $item !important;
      }
      .ml-#{$key}-#{$key1} {
        margin-left: $item !important;
      }
      .my-#{$key}-#{$key1} {
        margin-bottom: $item !important;
        margin-top: $item !important;
      }
      .mx-#{$key}-#{$key1} {
        margin-left: $item !important;
        margin-right: $item !important;
      }
    }
  }
}

.container {
  max-width: 1300px !important;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
}

.containerless {
  max-width: 1300px !important;
  margin-left: auto;
  margin-right: auto;
}

.container-fluid {
  width: 100%;
  height: 100vh;
}

.h-100 {
  height: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: -15px;
  margin-right: -15px;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}
.w-fiit {
  width: fit-content !important;
}
.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}

.w-30 {
  width: 30%;
}
.vh-max {
  max-height: 100vh;
}

.vh-min {
  min-height: 100vh;
}

.vw-max {
  max-width: 100vw;
}

.vw-min {
  min-width: 100vw;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.d-inline {
  display: inline;
}

.flex-wrap {
  flex-wrap: wrap;
}
.flex-wrap-alt {
  @media screen and (max-width: 767px) {
flex-direction: column;
  }

}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.d-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-justify-center {
  display: flex;
  justify-content: center;
}

.d-justify-between {
  display: flex;
  justify-content: space-between;
}

.d-justify-end {
  display: flex;
  justify-content: flex-end;
}

.d-justify-start {
  display: flex;
  justify-content: flex-start;
}
.d-justify-around {
  display: flex;
  justify-content: space-around;
}

.d-align-center {
  display: flex;
  align-items: center;
}

.d-align-end {
  display: flex;
  align-items: flex-end;
}

.d-align-start {
  display: flex;
  align-items: flex-start;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}


.position-fixed {
  position: fixed;
}

.border-radius {
  border-radius: 4px;
}

.shadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  &-lg {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.15);
  }
}

.rounded-circle {
  border-radius: 50%;
}

.rounded {
  border-radius: 4px;
}

.rounded-lg {
  border-radius: 7.5px !important;
}
.rounded-16 {
  border-radius: 14.5px !important;
}

.rounded-xl {
  border-radius: 24px !important;
}

.rounded-xxl {
  border-radius: 30px !important;
}
.rounded-lg-left {
  border-radius: 8px 0 0 8px;
}
.rounded-lg-right {
  border-radius: 0 8px 8px 0;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.table-container {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-size: rem(16);
}

td,
th {
  text-align: left;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #dee2e6;
}

th {
  padding: 1rem 1rem;
}

td {
  color: map-get($colors, "dark-50");
  font-weight: map-get($font-weights, "light");
  font-size: rem(15);
}

tbody tr {
  &:hover {
    background: rgba($color: map-get($colors, "light"), $alpha: 0.1);
  }
  &:last-child td {
    border-bottom: none !important;
  }
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}
.width-fit {
  width: fit-content !important;
}
.w-lg-50 {
  width: 100% !important;
}
.float{
  animation: floating 1s linear infinite;
}
@keyframes floating {
  0% {
    transform: translateY(-5px);
  }

  50% {
    transform: translateY(-2.5px);
  }

 
  100% {
    transform: translateY(0px);
  }
}
@media screen and (min-width: 768px) {
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
}
@media screen and (min-width: 768px) {
}
.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.overflow-scroll {
  overflow: scroll !important;
}
.no-scrollbar {
  &:-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
    -webkit-appearance: none !important;
    background-color: transparent;
    border-radius: 0;
  }
}
