@import "../../../../scss/config";

.input_container {
	input {
		background: $primary-white 0% 0% no-repeat padding-box;
		border: 2px solid $light-gray;
		border-radius: 6px;
		color: $primary-black;
		@include fluid-type($min_width, $max_width, $min_font_19, $max_font_19);
		@include fluid-padding-double(
			$min_width,
			$max_width,
			$min_padding_14,
			$max_padding_14,
			$min_padding_20,
			$max_padding_20
		);
		&::placeholder {
			color: $light-gray;
			@include fluid-type($min_width, $max_width, $min_font_18, $max_font_18);
		}
		&:hover,
		&:focus,
		&:active {
			border: 2px solid$primary-blue;
			outline: none;
		}
	}
}
.input_container_icons {
	.input_box {
		background: $primary-white 0% 0% no-repeat padding-box;
		border: 2px solid $light-gray;
		border-radius: 6px;
		color: $primary-black;
		@include fluid-padding-double(
			$min_width,
			$max_width,
			$min_padding_14,
			$max_padding_14,
			$min_padding_20,
			$max_padding_20
		);
		&:hover,
		&:focus,
		&:active {
			border: 2px solid$primary-blue;
			outline: none;
		}
		input {
			@include fluid-type($min_width, $max_width, $min_font_19, $max_font_19);
			&::placeholder {
				color: $light-gray;
			}
			border: none;
			font-size: 16px;
			width: 100%;
			&:focus {
				outline: none;
			}
		}
		path {
			fill: $light-gray;
		}
	}
}
