.patner-flex {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.partner-btn {
	transform: scale(0.8);

	&-text {
		font-size: 10px;
	}
}
