/** @format */
@import "./functions";

// @font-face {
//   font-family: "NoirProLight";
//   src: local("NoirProLight"),
//     url(../assets/fonts/NoirPro-Light.ttf) format("ttf");
// }
// Fonts
@each $key, $val in $font-weights {
	.font-weight-#{$key} {
		font-weight: $val !important;
	}
}

// Colors
@each $key, $val in $colors {
	.text-#{$key} {
		color: $val !important;
	}
	.bg-#{$key} {
		background: $val !important;
	}
	.bg-#{$key}-light {
		background: transparentize($color: $val, $amount: 0.8);
	}
	.bg-none {
		background: none;
	}
	.bg-transparent {
		background: rgba(255, 255, 255, 0.1) !important;
	}
	.btn-#{$key} {
		background: $val !important;
		box-shadow: 0 0 0 0 3px transparentize($color: $val, $amount: 0.8);
		color: map-get($colors, "white");
		transition: background 0.5s ease;
		&:hover {
			background: darken($color: $val, $amount: 4) !important;
		}
		&:disabled {
			background: lighten($color: $val, $amount: 5) !important;
		}
	}
	.btn-outline-#{$key} {
		color: $val !important;
		border: 1px solid transparentize($color: $val, $amount: 0.1) !important;

		&:hover {
			background: transparentize($color: $val, $amount: 0.9) !important;
		}
	}

	.btn-outline-primary {
		&:hover {
			color: #fff;
		}
	}

	.border-#{$key} {
		border: 1px solid $val;
	}
	.border-bottom-#{$key} {
		border-bottom: 1px solid $val;
	}

	.border-right-#{$key} {
		border-right: 1px solid $val;
	}
	.border-top-#{$key} {
		border-top: 1px solid $val;
	}

	@media screen and (min-width: 768px) {
		.border-after-md-#{$key} {
			&::after {
				content: "";
				width: 1px;
				height: 250px;
				background-color: $val !important;
				position: absolute;
				right: 0;
				opacity: 0.2 !important;
			}
		}
	}

	@media screen and (min-width: 500px) {
		.border-after-sm-#{$key} {
			&::after {
				content: "";
				width: 1px;
				height: 250px;
				background-color: $val !important;
				position: absolute;
				right: 0;
				opacity: 0.2 !important;
			}
		}
	}
	.border-after-none {
		&::after {
			content: "";
			width: 1px;
			height: 250px;
			background-color: $val;
			position: absolute;
			right: 0;
			opacity: 0 !important;
		}
	}
	.alert-#{$key} {
		background: transparentize($color: $val, $amount: 0.8);
		color: $val;
	}
	.alert__#{$key} {
		background: $val;
		color: map-get($colors, "white");
	}
}

.bg-light {
	background: #f7f7f7;
}

.border-bottom-grey {
	border-bottom: 0.5px solid transparentize($color: #f7f7f7, $amount: 0.5);
}

.border-dashed {
	border: 0.5px dashed map-get($colors, light);
}

.text-uppercase {
	text-transform: uppercase;
}
.text-thin {
	font-weight: 100;
}
.text-fade {
	opacity: 0.8;
}
// Font sizes

h1,
.h1 {
	font-size: 3rem;
	line-height: 1.5em;
	// margin-bottom: 0.5em;
	font-weight: map-get($font-weights, "medium");
}

h2,
.h2 {
	font-size: 2.5rem;
	line-height: 1.5em;
	// margin-bottom: 0.5em;
	font-weight: map-get($font-weights, "medium");
}

h3,
.h3 {
	font-size: 2rem;
	line-height: 1.5em;
	// margin-bottom: 0.5em;
	font-weight: map-get($font-weights, "medium");
}

h4,
.h4 {
	font-size: 1.875rem;
	line-height: 1.5em;
	// margin-bottom: 0.5em;
	font-weight: map-get($font-weights, "medium");
}

h5,
.h5 {
	font-size: 1.5rem;
	line-height: 1.5em;
	// margin-bottom: 0.5em;
	font-weight: map-get($font-weights, "regular");
}

h6,
.h6 {
	// font-size: 1.25rem;
	// line-height: 1.5em;
	// margin-bottom: 0.5em;
	font-weight: map-get($font-weights, "light");
	&.small {
		font-size: 1.0625rem !important;
	}
}

p,
.p,
a {
	font-size: 1rem;
	line-height: 1.5em;
	// margin-bottom: 0.5em;
	font-weight: map-get($font-weights, "light");
}
ul,
li {
	list-style-type: none;
}
.underlined {
	text-decoration: underline;
}
.small,
small {
	font-size: 0.875rem;
	line-height: 1.5em;
}

.x-small {
	font-size: 0.75rem;
	line-height: 1.2em;
}

.xs-small {
	font-size: 0.625rem;
	line-height: 1.2em;
}
.xxs-small {
	font-size: 0.575rem;
	line-height: 1.2em;
	white-space: nowrap;
}
.spaced {
	line-height: 2.4em;
}
.text-italize {
	font-style: italic;
}

a:active {
	color: map-get($colors, primary);
}
.bold {
	font-weight: bold !important;
}

.bolder {
	font-weight: bolder !important;
}
.no-wrap {
	white-space: nowrap;
}
.no-underline {
	text-decoration: none;
}

.no-wrap-lg {
	white-space: nowrap;
}
.p-sm {
	padding: 2px;
}
.py-sm {
	padding-top: 2px;
	padding-bottom: 2px;
}
// Font families
.inter {
	font-family: "Inter", sans-serif;
}
.product-sans {
	font-family: "Product-Sans", sans-serif;
}

.aileron {
	font-family: "Aileron", sans-serif;
}
.aileron-light {
	font-family: "Aileron-Light", sans-serif;
}
.faded {
	opacity: 0.5;
}
.no-fade {
	opacity: 1 !important;
}
.text-xl {
	font-size: rfs(30, 25);
}
.text-18 {
	font-size: rfs(17, 15);
}
.text-20 {
	font-size: rfs(19, 17);
	line-height: 35px;
}
.text-x {
	font-size: rfs(22, 20);
}
.text-n {
	font-size: rfs(16, 13);
	line-height: 26px;
}
.text-14 {
	font-size: rfs(13, 12);
}

.text-sm {
	font-size: 0.875rem;
	line-height: 1.25rem;
}
.text-b {
	font-weight: 600;
}
.normal {
	font-weight: normal;
}
.spaced-xl {
	line-height: rfs(50, 42);
}
.uppercase {
	text-transform: uppercase;
}
.capitalize {
	text-transform: capitalize;
}
// .noir-light {
//   font-family: NoirProLight sans-serif;
// }
.helvetica {
	font-family: Helvetica;
}
.pointer {
	cursor: pointer;
}
.underline {
	text-decoration: underline;
}
.space-height {
	line-height: 30px !important;
}
