$primary-red: #ff0000;
$light-red: #ffc7c7;
$primary-black: #000000;
$primary-gray: #939393;
$secondary-gray: #acacac;
$grey_white: #bfbfbf;
$primary-white: #ffffff;
$border-gray: #707070;
$main-gray: #e8e8e8;
$deep-gray: rgba(0, 0, 0, 0.214);
$light-gray: rgba(0, 0, 0, 0.165);

$primary-green: #1fc900;
$primary-blue: #2699fb;
$transparent-blue: #2698fb2f;
@mixin d_flex($direction, $align, $justify) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}
@mixin no_style() {
  background: none;
  border: none;
  outline: none;
  &:hover {
    border: none;
    outline: none;
  }
  &:active {
    border: none;
    outline: none;
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@mixin fluid-width($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      width: $min-font-size;
      @media screen and (min-width: $min-vw) {
        width: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }

      @media screen and (min-width: $max-vw) {
        width: $max-font-size;
      }
    }
  }
}

@mixin fluid-height($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      height: $min-font-size;
      @media screen and (min-width: $min-vw) {
        height: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }

      @media screen and (min-width: $max-vw) {
        height: $max-font-size;
      }
    }
  }
}

@mixin fluid-padding(
  $min-vw,
  $max-vw,
  $min-top,
  $max-top,
  $min-right,
  $max-right,
  $min-bottom,
  $max-bottom,
  $min-left,
  $max-left
) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);

  $u3: unit($min-top);
  $u4: unit($max-top);

  $u5: unit($min-right);
  $u6: unit($max-right);

  $u7: unit($min-bottom);
  $u8: unit($max-bottom);

  $u9: unit($min-left);
  $u10: unit($max-left);

  @if $u1 ==
    $u2 and
    $u1 ==
    $u3 and
    $u1 ==
    $u4 and
    $u1 ==
    $u5 and
    $u1 ==
    $u6 and
    $u1 ==
    $u7 and
    $u1 ==
    $u8 and
    $u1 ==
    $u9 and
    $u1 ==
    $u10
  {
    & {
      padding: $min-top $min-right $min-bottom $min-left;
      @media screen and (min-width: $min-vw) {
        padding: calc(
            #{$min-top} + #{strip-unit($max-top - $min-top)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          )
          calc(
            #{$min-right} + #{strip-unit($max-right - $min-right)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          )
          calc(
            #{$min-bottom} + #{strip-unit($max-bottom - $min-bottom)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          )
          calc(
            #{$min-left} + #{strip-unit($max-left - $min-left)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          );
      }

      @media screen and (min-width: $max-vw) {
        padding: $max-top $max-right $max-bottom $max-left;
      }
    }
  }
}

@mixin fluid-padding-double(
  $min-vw,
  $max-vw,
  $min-font-size,
  $max-font-size,
  $min-font-size-two,
  $max-font-size-two
) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  $u5: unit($min-font-size-two);
  $u6: unit($max-font-size-two);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 and $u1 == $u5 and $u1 == $u6 {
    & {
      padding: $min-font-size $min-font-size-two;
      @media screen and (min-width: $min-vw) {
        padding: calc(
            #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          )
          calc(
            #{$min-font-size-two} + #{strip-unit(
                $max-font-size-two - $min-font-size-two
              )} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          );
      }

      @media screen and (min-width: $max-vw) {
        padding: $max-font-size $max-font-size-two;
      }
    }
  }
}

@mixin fluid-margin(
  $min-vw,
  $max-vw,
  $min-top,
  $max-top,
  $min-right,
  $max-right,
  $min-bottom,
  $max-bottom,
  $min-left,
  $max-left
) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);

  $u3: unit($min-top);
  $u4: unit($max-top);

  $u5: unit($min-right);
  $u6: unit($max-right);

  $u7: unit($min-bottom);
  $u8: unit($max-bottom);

  $u9: unit($min-left);
  $u10: unit($max-left);

  @if $u1 ==
    $u2 and
    $u1 ==
    $u3 and
    $u1 ==
    $u4 and
    $u1 ==
    $u5 and
    $u1 ==
    $u6 and
    $u1 ==
    $u7 and
    $u1 ==
    $u8 and
    $u1 ==
    $u9 and
    $u1 ==
    $u10
  {
    & {
      margin: $min-top $min-right $min-bottom $min-left;
      @media screen and (min-width: $min-vw) {
        margin: calc(
            #{$min-top} + #{strip-unit($max-top - $min-top)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          )
          calc(
            #{$min-right} + #{strip-unit($max-right - $min-right)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          )
          calc(
            #{$min-bottom} + #{strip-unit($max-bottom - $min-bottom)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          )
          calc(
            #{$min-left} + #{strip-unit($max-left - $min-left)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          );
      }

      @media screen and (min-width: $max-vw) {
        margin: $max-top $max-right $max-bottom $max-left;
      }
    }
  }
}
$min_width: 320px;
$max_width: 1660px;

$min_font_14: 10.5px;
$max_font_14: 12px;

$min_font_16: 11.5px;
$max_font_16: 13px;

$min_font_18: 12.5px;
$max_font_18: 14px;

$min_font_19: 13px;
$max_font_19: 15.5px;

$min_font_20: 12px;
$max_font_20: 17px;

$min_font_23: 12.5px;
$max_font_23: 18px;

$min_font_24: 14px;
$max_font_24: 20px;

$min_font_52: 22px;
$max_font_52: 30px;

$min_padding_20: 8px;
$max_padding_20: 11px;

$min_padding_14: 5px;
$max_padding_14: 11px;

$min_padding_25: 11px;
$max_padding_25: 18px;

$min_padding_40: 14px;
$max_padding_40: 30px;

$min_padding_50: 19px;
$max_padding_50: 38px;

$min_padding_70: 22px;
$max_padding_70: 50px;

$min_margin_25: 9px;
$max_margin_25: 18px;

$min_margin_30: 10px;
$max_margin_30: 20px;

$min_margin_15: 6px;
$max_margin_15: 11px;

$min_margin_17: 7px;
$max_margin_17: 14px;

$min_margin_10: 5px;
$max_margin_10: 8px;

$min_margin_35: 13px;
$max_margin_35: 23px;

$min_margin_45: 17px;
$max_margin_45: 30px;

$min_height_200: 90px;
$max_height_200: 140px;
