@import "../../scss/config";

.users_container {
	.tabs-container {
		border: 1px solid $primary-red;

		.app-tab {
			font-weight: 600;
			font-size: 18px;
		}
	}
	.left_section {
		@media screen and (max-width: 767px) {
			padding-right: 0;
		}
		.bottom_left_section {
			max-height: 1000px;
			box-shadow: 0px 2.5px 5px #00000029;
			border-radius: 4px;
			padding: 1em 1.5em 1.2em;
			overflow: scroll;
			table {
				overflow: scroll;
				border-collapse: separate;
				border-spacing: 0;
				position: relative;
				tbody {
					tr {
						td {
							border-bottom: 1px solid $light-gray;
							padding: 0.8em 0;
						}
					}
				}
			}
		}
	}
	.right_section {
		box-shadow: 0px 2.5px 5px #00000029;
		border-radius: 4px;
		.borderless_dates {
			width: 100%;
			@include fluid-padding-double(
				$min_width,
				$max_width,
				$min_padding_20,
				$max_padding_20,
				$min_padding_40,
				$max_padding_40
			);
			.rdt {
				width: 40%;
			}
			.rdtPicker {
				left: -30px;
			}
		}
	}
}

.bottom_left_section {
	max-height: 1000px;
	box-shadow: 0px 2.5px 5px #00000029;
	border-radius: 4px;
	padding: 1em 1em 1.2em;
	// overflow: scroll;
	.overflow-scroll {
		max-height: 800px;
	}
	table {
		overflow: scroll;
		border-collapse: separate;
		border-spacing: 0;
		position: relative;
		tbody {
			tr {
				td {
					border-bottom: 1px solid $light-gray;
					padding: 0.8em 0;
				}
			}
		}
	}
}
