@import "../../scss/config";
.back_drop {
	width: 100vw;
	height: 100vh;
	position: fixed;
	background: $light-gray;
	top: 0;
	left: 0;
	z-index: 9;
	&.back_drop_alt {
		z-index: 99 !important;
	}
}
.modal {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	.close_button {
		position: absolute;
		top: 20px;
		right: 20px;
		font-size: 24px;
		z-index: 99;
		color: $primary-red;
	}
	form {
		top: 0px;
		margin-top: 10px;
		background: $primary-white;
		padding: 25px;
		border-radius: 8px;
		z-index: 99;
		position: relative;
		width: 50%;
		min-height: 450px !important;
		@media screen and (max-width: 767px) {
			width: 90%;
		}
	}
}
.small_modal {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	.close_button {
		position: absolute;
		top: 20px;
		right: 20px;
		font-size: 24px;
		z-index: 99;
		color: $primary-red;
	}

	form {
		top: 0px;
		margin-top: 10px;
		background: $primary-white;
		padding: 25px;
		border-radius: 8px;
		z-index: 99;
		position: relative;
		width: 50%;

		@media screen and (max-width: 767px) {
			width: 90%;
		}
	}
}

.prompt {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	.close_button {
		position: absolute;
		top: 20px;
		right: 20px;
		font-size: 24px;
		z-index: 99;
		color: $primary-red;
	}
	form {
		top: 0px;
		margin-top: 10px;
		background: $primary-white;
		padding: 60px 25px;
		border-radius: 8px;
		z-index: 99;
		position: relative;
		width: 50%;
		@media screen and (max-width: 767px) {
			width: 90%;
		}
	}
}

.right_input {
	margin: 0 0 10px 7px;
}
.left_input {
	margin: 0 7px 10px 0;
}
.right_input,
.left_input {
	@media screen and (max-width: 767px) {
		margin: 0 0 8px 0;
	}
}
