@import "../../scss/config";
.header {
	background: $primary-white 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	padding: 1rem 3rem;
	height: fit-content;
	width: 100%;
	margin-bottom: 28px;
	figure {
		margin: 0;
		img {
			width: 30px;
			height: 30px;
		}
	}
}
