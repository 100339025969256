@import "../../scss/config";
.card {
	box-shadow: 0px 2.5px 5px rgba(0, 0, 0, 0.16);
	border-radius: 8px;
	min-height: 82px;
}
.alt_card {
	box-shadow: 0px 2.5px 5px rgba(0, 0, 0, 0.16);
	border-radius: 8px;
	display: flex;

	justify-content: space-between;
	width: 100%;

	@media (max-width: 768px) {
		// flex-wrap: wrap;
	}

	&_col {
		display: flex;
		flex-direction: column;
		grid-row-gap: 0.5rem;
	}

	&_one {
		width: 25%;
	}

	&_two {
		width: 50%;
	}

	&_three {
		width: 25%;
	}

	@media (max-width: 768px) {
		&_one,
		&_two,
		&_three {
			width: initial;
			grid-gap: 0;
		}
	}

	.text-wrap {
		width: 50%;
	}
}

.active_card {
	border: 1px solid $primary-green;
}
.alt_card_active {
	border: 1px solid $primary-red;
}
.card_loader {
	animation: fading 1s linear infinite;
}
